import { makeStyles, Box, Typography } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import { SimulationPatternEnum } from "../../../../api/generated";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { PrLineChart } from "../../../../scripts/components/renewal_v1/linechart/linechart";
import { PrBalanceChart } from "../../../../scripts/components/renewal_v1/signedbarchart/balance_chart";
import { SelectedProperty } from "../../../../view_models/property_selectors";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.grey[50],
    paddingTop: 8,
    paddingBottom: 100,
  },
  nearbyLink: {
    height: 71,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 24px",
    marginBottom: 8,
  },
  arrowIcon: {
    width: 16,
    height: 16,
  },
  graphCard: {
    backgroundColor: theme.palette.background.paper,
    padding: 24,
    marginBottom: 8,
  },
}));

export const ManagementDetailSimulation: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const selected_property = useRecoilValue(SelectedProperty);
  const [simulationPattern] = React.useState<SimulationPatternEnum>(
    selected_property.simulation_configs[0]?.simulation_pattern ?? SimulationPatternEnum.楽観的
  );

  return (
    <Box className={classes.background}>
      <Box className={classes.graphCard}>
        {/* 物件価格とローン残高推移 */}
        <Typography variant="h3">物件価格とローン残高推移</Typography>
        <Typography variant="body2">シミュレーション「{simulationPattern}」で設定されています。</Typography>
        <PrLineChart />
      </Box>

      <Box className={classes.graphCard}>
        {/* 将来収支 */}
        <Typography variant="h3">将来収支</Typography>
        <PrBalanceChart />
      </Box>

      <Box mt={2}>
        {/* シミュレーション設定を変更 */}
        <CustomButton
          customVariant="outlinedGray"
          isEdit
          onClick={async () => {
            history.push("/management/detail/simulation");
          }}
        >
          シミュレーション設定を変更
        </CustomButton>
      </Box>
    </Box>
  );
};
