import { Box, Typography, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useSetRecoilState } from "recoil";

import { AssessmentProposal } from "../../../../api/generated";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { AgentProfile } from "../../../../scripts/components/renewal_v1/offer/agent_profile";
import { OfferApproveModal } from "../../../../scripts/components/renewal_v1/offer/approve_modal";
import { OfferDetailContent } from "../../../../scripts/components/renewal_v1/offer/content";
import { OfferDetailHeadline } from "../../../../scripts/components/renewal_v1/offer/headline";
import { PropertyAppraisedPrice } from "../../../../scripts/components/renewal_v1/offer/property_appraised_price";
import { OfferStrengthChip } from "../../../../scripts/components/renewal_v1/offer/strength_chip";
import { PropertyOperationTemplate } from "../../../../scripts/components/renewal_v1/template/property_operation_template";
import { assessmentUnreadCount } from "../../../../view_models/proposal_unread_counts";
import { Loading } from "../../../loading";

const useStyles = makeStyles((theme) => ({
  offerContent: {
    paddingBottom: 16,
    marginBottom: 16,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export const OfferAssessmentDetailPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [proposalData, setProposalData] = React.useState<AssessmentProposal>(null);
  const [isApproveModalOpen, setIsApproveModalOpen] = React.useState(false);
  const setAssessmentUnreadCount = useSetRecoilState(assessmentUnreadCount);

  const handleClickPageBack = () => {
    history.push("/offer");
  };

  React.useEffect(() => {
    const fp = PropallyApiFactory();
    const updateAssessmentReadById = async () => {
      // 既読にする
      try {
        const data = await fp.v1AssessmentProposalsAssessmentProposalIdReadPatch(Number(id), { withCredentials: true });
        if (data.status === 200) {
          console.log("read investment proposals", data.data);
        }
      } catch (e) {
        console.error(e);
      }
      // 未読数を取得しatomの更新
      const response = await fp.v1AssessmentProposalUnreadCountGet({
        withCredentials: true,
      });
      setAssessmentUnreadCount(response.data as { unread_count: number });
    };
    const fetchAssessmentProposalById = async () => {
      try {
        const data = await fp.v1AssessmentProposalsAssessmentProposalIdGet(Number(id), { withCredentials: true });
        if (data.status === 200) {
          setProposalData(data.data);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    // 既読
    updateAssessmentReadById();
    // 詳細取得
    fetchAssessmentProposalById();
  }, []);

  if (loading) {
    <PropertyOperationTemplate title="査定オファー詳細" onBack={handleClickPageBack}>
      <Loading />;
    </PropertyOperationTemplate>;
  }

  // エージェント情報
  const agentData = React.useMemo(() => {
    return proposalData?.agent;
  }, [proposalData]);

  // 特に強い分野の取得
  const strongFields = React.useMemo(() => {
    const tags = proposalData?.agent?.company?.speciality_tags.map((tag) => {
      return tag.name;
    });
    return tags;
  }, [proposalData]);

  // 話を聞いてみるボタンの押下時
  const handleClickModalApprove = () => {
    window.scrollTo(0, 0);
    const patchAssessmentProposal = async () => {
      const fp = PropallyApiFactory();

      try {
        const data = await fp.v1AssessmentProposalsAssessmentProposalIdApprovedPatch(
          Number(id),
          { is_approved: true },
          { withCredentials: true }
        );
        if (data.status === 200) {
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
      setIsApproveModalOpen(true);
    };
    patchAssessmentProposal();
  };

  return (
    <PropertyOperationTemplate title="査定オファー詳細" onBack={handleClickPageBack}>
      <Box p={3} whiteSpace="pre-wrap">
        {/* 日付 */}
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption" color="textSecondary">
            {dayjs(proposalData?.proposal_date).format("YYYY年MM月DD日")}
          </Typography>
        </Box>
        <Box className={classes.offerContent}>
          {/* 物件査定額 */}
          <PropertyAppraisedPrice
            proposalPrice={proposalData?.proposal_price || 0}
            propertyName={proposalData?.property?.name || ""}
          />
        </Box>

        {/* プロフィール画像 */}
        <AgentProfile
          profileImagePath={proposalData?.agent?.agent_profile_image?.image_url || ""}
          companyImagePath={proposalData?.agent?.company?.company_logo_image?.image_url || ""}
          companyName={proposalData?.agent?.company?.name || ""}
          representativeName={proposalData?.agent?.family_name + proposalData?.agent?.first_name || ""}
        />

        {/* メッセージ */}
        <Box my={2}>
          <Typography variant="body1">{proposalData?.proposal_content}</Typography>
        </Box>

        {/* 営業時間 */}
        <Box>
          <Typography variant="body1" color="textPrimary">
            【 営業時間 】
          </Typography>
          <Typography variant="body1" color="textPrimary">
            9:00〜18:00 (土日祝除く)
          </Typography>
        </Box>

        {/* 特に強い分野 */}
        {strongFields?.length > 0 && (
          <Box mt={2} mb={5}>
            <Box display="flex" flexWrap="wrap" mt={1}>
              {strongFields.map((field, index) => (
                <OfferStrengthChip label={field} key={"strength-" + index} />
              ))}
            </Box>
          </Box>
        )}

        {/* お客様へ提供できること */}
        <Box mb={5}>
          <OfferDetailHeadline text="お客様へ提供できること" />
          {/* 会社の実績 */}
          {agentData?.company?.achievements && (
            <OfferDetailContent title="会社の実績" content={agentData.company.achievements} />
          )}

          {/* 販売物件の強み */}
          {agentData?.company?.strengths_of_properties_for_sale && (
            <OfferDetailContent title="販売物件の強み" content={agentData.company.strengths_of_properties_for_sale} />
          )}

          {/* 物件買取の強み */}
          {agentData?.company?.strengths_of_properties_for_purchase && (
            <OfferDetailContent
              title="物件買取の強み"
              content={agentData.company.strengths_of_properties_for_purchase}
            />
          )}

          {/* 提携金融機関の強み */}
          {agentData?.company?.strengths_of_partner_financial_institutions && (
            <OfferDetailContent
              title="提携金融機関の強み"
              content={agentData.company.strengths_of_partner_financial_institutions}
            />
          )}

          {/* 管理の強み */}
          {agentData?.company?.strengths_of_properties_for_management && (
            <OfferDetailContent title="管理の強み" content={agentData.company.strengths_of_properties_for_management} />
          )}

          {/* 不動産以外の資産形成アドバイス、購入後アフターフォロー */}
          {agentData?.company?.advice_except_real_estate && (
            <OfferDetailContent
              title="不動産以外の資産形成アドバイス、購入後アフターフォロー"
              content={agentData.company.advice_except_real_estate}
            />
          )}

          {/* 税理士など提携パートナー */}
          {agentData?.company?.partner && (
            <OfferDetailContent title="税理士など提携パートナー" content={agentData.company.partner} />
          )}

          {/* 他アピールポイント */}
          {agentData?.company?.appeal_point && (
            <OfferDetailContent title="他アピールポイント" content={agentData.company.appeal_point} />
          )}
        </Box>

        {/* 私のプロフィール */}
        <Box mb={5}>
          <OfferDetailHeadline text="私のプロフィール" />
          <Box>
            <Typography variant="body1" color="textPrimary">
              {proposalData?.agent?.profile}
            </Typography>
          </Box>
        </Box>

        {/* 話を聞いてみる */}
        <Box mb={2} sx={{ position: "fixed", bottom: 0, right: 0, left: 0, zIndex: 1 }}>
          <CustomButton
            customVariant="filled"
            onClick={handleClickModalApprove}
            disabled={proposalData?.is_user_approved}
          >
            話を聞いてみる
          </CustomButton>
        </Box>
      </Box>
      {isApproveModalOpen && <OfferApproveModal offerType="assessment" onClose={() => setIsApproveModalOpen(false)} />}
    </PropertyOperationTemplate>
  );
};
