import { makeStyles, Box } from "@material-ui/core";
import * as React from "react";

import { LatestHistoryContent } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history";
import { PropertyOperationTemplate } from "../../../../../../scripts/components/renewal_v1/template/property_operation_template";
import {
  getFormatCost,
  getFormatStartMonth,
  getLatestMonthlyCashflowResult,
} from "../../../../../../utilities/renewal_v1/property_edit";
import { PropertyHolder } from "../../../../../../view_models/atoms";

import { EditPropertySectionPropertyInfoExtraHistory, propertyInfoExtraCostType } from "./history";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },
}));

interface props {
  propertyHolder: PropertyHolder;
  onClickPageBack: () => void;
}

export const EditPropertySectionPropertyInfoExtra: React.FC<props> = ({ propertyHolder, onClickPageBack }) => {
  const classes = useStyles();
  // 過去履歴の表示・非表示
  const [isShowHistory, setIsShowHistory] = React.useState(false);
  // 過去履歴ページに渡す情報
  const [selectedCostType, setSelectedCostType] = React.useState<propertyInfoExtraCostType | null>(null);

  /*
   各種コストの取得
  */
  // property_tax を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultPropertyTax = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(propertyHolder.user_input_monthly_cashflow_results, "property_tax");
  }, [propertyHolder.user_input_monthly_cashflow_results]);
  const propertyTax = React.useMemo(
    () => getFormatCost(latestMonthlyCashflowResultPropertyTax?.property_tax),
    [latestMonthlyCashflowResultPropertyTax]
  );

  // large_scale_repair_fund_cost を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultLargeScaleRepairFundCost = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(
      propertyHolder.user_input_monthly_cashflow_results,
      "large_scale_repair_fund_cost"
    );
  }, [propertyHolder.user_input_monthly_cashflow_results]);
  const largeScaleRepairFundCost = React.useMemo(
    () => getFormatCost(latestMonthlyCashflowResultLargeScaleRepairFundCost?.large_scale_repair_fund_cost),
    [latestMonthlyCashflowResultLargeScaleRepairFundCost]
  );

  // misc_onetime_cost を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultMiscOnetimeCost = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(propertyHolder.user_input_monthly_cashflow_results, "misc_onetime_cost");
  }, [propertyHolder.user_input_monthly_cashflow_results]);
  const miscOnetimeCost = React.useMemo(
    () => getFormatCost(latestMonthlyCashflowResultMiscOnetimeCost?.misc_onetime_cost),
    [latestMonthlyCashflowResultMiscOnetimeCost]
  );

  const onClickPageBackFromHistory = () => {
    setIsShowHistory(false);
  };
  if (isShowHistory) {
    return (
      <EditPropertySectionPropertyInfoExtraHistory
        costType={selectedCostType}
        onClickPageBack={onClickPageBackFromHistory}
      />
    );
  }

  const handleClickShowMoreHistory = (propertyInfoExtraCostType: propertyInfoExtraCostType) => {
    setSelectedCostType(propertyInfoExtraCostType);
    setIsShowHistory(true);
  };

  return (
    <PropertyOperationTemplate title="物件詳細" onBack={onClickPageBack}>
      <Box>
        <Box className={classes.content}>
          {/* 固定資産税・都市計画税 (年) */}
          <LatestHistoryContent
            title="固定資産税・都市計画税 (年)"
            contents={[
              { label: "支払開始月", value: getFormatStartMonth(latestMonthlyCashflowResultPropertyTax) },
              { label: "金額", value: propertyTax },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("固定資産税・都市計画税")}
          />

          {/* 設備修繕、大規模修繕費用 */}
          <LatestHistoryContent
            title="設備修繕、大規模修繕費用"
            contents={[
              { label: "支払開始月", value: getFormatStartMonth(latestMonthlyCashflowResultLargeScaleRepairFundCost) },
              { label: "金額", value: largeScaleRepairFundCost },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("設備修繕、大規模修繕費用")}
          />

          {/* その他一過性の支出 */}
          <LatestHistoryContent
            title="その他一過性の支出"
            contents={[
              { label: "支払開始月", value: getFormatStartMonth(latestMonthlyCashflowResultMiscOnetimeCost) },
              { label: "金額", value: miscOnetimeCost },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("その他一過性の支出")}
          />
        </Box>
      </Box>
    </PropertyOperationTemplate>
  );
};
