import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router";

import OfferInvestmentImage from "../../../../../assets/image/offer_investment.png";
import OfferInvestmentNotRecievedImage from "../../../../../assets/image/offer_investment_not_recieved.png";
import { InvestmentProposal } from "../../../../api/generated";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import { NoContentView } from "../../../../scripts/components/renewal_v1/no_content";
import { OfferInvestmentCard } from "../../../../scripts/components/renewal_v1/offer/investment_card";
import CustomPagination from "../../../../scripts/components/renewal_v1/pagination";
import { calculatePaginationInvestmentProposal } from "../../../../utilities/renewal_v1/pagination";
import { Loading } from "../../../loading";

const useStyles = makeStyles((_theme) => ({
  image: {
    width: 80,
    height: "auto",
  },
}));

export const OffferInvestmentTab: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [investmentProposals, setInvestmentProposals] = React.useState<InvestmentProposal[]>([]);
  // ページネーション
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  React.useEffect(() => {
    const fetchInvestmentProposals = async () => {
      const fp = PropallyApiFactory();
      try {
        const data = await fp.v1InvestmentProposalsGet({ withCredentials: true });
        if (data.status === 200) {
          setInvestmentProposals(data.data);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchInvestmentProposals();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ページネーションのためのロジック
  const { currentInvestmentProposals, totalPages } = calculatePaginationInvestmentProposal(
    investmentProposals,
    currentPage
  );

  // 詳細選択
  const onClickInvestmentDetail = (investmentProposalId: number) => {
    history.push(`/investment/${investmentProposalId}`);
  };

  if (loading) {
    return <Loading />;
  }

  if (investmentProposals.length === 0) {
    return (
      <NoContentView
        mainMessage="まだ投資オファーが届いていません"
        subMessage="オファーが届きやすいよう、検討している物件の情報を登録しましょう。"
        buttonText="検討物件を登録する"
        image={OfferInvestmentNotRecievedImage}
      />
    );
  }

  return (
    <Box>
      <Box pb={3} display="flex" alignItems="center">
        <img src={OfferInvestmentImage} alt="receiving_investment_offer" className={classes.image} />
        <Box>
          <Box mb={1}>
            <Typography variant="h4">あなたに投資オファーが届いています！</Typography>
          </Box>
          <Typography variant="body2">不動産の専門家から話を聞いて投資不動産について知識を深めましょう。</Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {currentInvestmentProposals.map((investmentProposal) => (
          <Grid item xs={12} sm={6} key={investmentProposal.id}>
            <OfferInvestmentCard
              profileImagePath={investmentProposal.agent?.agent_profile_image?.image_url || ""}
              proposalDate={investmentProposal?.proposal_date}
              companyImagePath={investmentProposal?.agent?.company?.company_logo_image?.image_url || ""}
              companyName={investmentProposal?.agent?.company?.name}
              representativeName={investmentProposal?.agent?.family_name + investmentProposal?.agent?.first_name}
              companySpecialityTags={investmentProposal?.agent?.company?.speciality_tags}
              proposalMessage={investmentProposal?.proposal_content}
              isUserApproved={investmentProposal?.is_user_approved}
              isNew={!investmentProposal?.is_user_read}
              isRead={investmentProposal?.is_user_read}
              onClick={() => onClickInvestmentDetail(investmentProposal.id)}
            />
          </Grid>
        ))}
      </Grid>
      <Box pt={2} pb={12}>
        <CustomPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Box>
    </Box>
  );
};
