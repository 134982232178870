import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router";

import OfferAssessmentImage from "../../../../../assets/image/offer_assessment.png";
import OfferAssessmentNotRecievedImage from "../../../../../assets/image/offer_assessment_not_recieved.png";
import { AssessmentProposal } from "../../../../api/generated";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import { NoContentView } from "../../../../scripts/components/renewal_v1/no_content";
import { OfferAssessmentCard } from "../../../../scripts/components/renewal_v1/offer/assessment_card";
import CustomPagination from "../../../../scripts/components/renewal_v1/pagination";
import { calculatePaginationAssessmentProposal } from "../../../../utilities/renewal_v1/pagination";
import { Loading } from "../../../loading";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 80,
    height: "auto",
  },
}));

export const OfferAssessmentTab: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [assessmentProposals, setAssessmentProposals] = React.useState<AssessmentProposal[]>([]);
  // ページネーション
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  React.useEffect(() => {
    const fetchAssessmentProposals = async () => {
      const fp = PropallyApiFactory();
      try {
        const data = await fp.v1AssessmentProposalsGet({ withCredentials: true });
        if (data.status === 200) {
          setAssessmentProposals(data.data);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchAssessmentProposals();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ページネーションのためのロジック
  const { currentAssessmentProposals, totalPages } = calculatePaginationAssessmentProposal(
    assessmentProposals,
    currentPage
  );

  // 詳細選択
  const onClickAssessmentDetail = (assessmentProposalId: number) => {
    history.push(`/assessment/${assessmentProposalId}`);
  };

  // 物件ページに遷移
  const onClickToProperty = () => {
    history.push("/management");
  };

  if (loading) {
    return <Loading />;
  }

  if (assessmentProposals.length === 0) {
    return (
      <NoContentView
        mainMessage="まだ査定オファーが届いていません"
        subMessage="オファーが届きやすいよう、保有している物件の情報を登録しましょう。"
        buttonText="保有物件を登録する"
        image={OfferAssessmentNotRecievedImage}
        onClick={onClickToProperty}
      />
    );
  }
  return (
    <Box>
      <Box pb={3} display="flex" alignItems="center">
        <img src={OfferAssessmentImage} alt="receiving_investment_offer" className={classes.image} />
        <Box>
          <Box mb={1}>
            <Typography variant="h4">あなたに査定オファーが届いています！</Typography>
          </Box>
          <Typography variant="body2">
            不動産の専門家に、物件の資産価値や物件の売却について相談してみましょう。
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {currentAssessmentProposals.map((assessmentProposal) => (
          <Grid item xs={12} sm={6} key={assessmentProposal.id}>
            <OfferAssessmentCard
              profileImagePath={assessmentProposal.agent?.agent_profile_image?.image_url || ""}
              proposalDate={assessmentProposal?.proposal_date}
              companyImagePath={assessmentProposal?.agent?.company?.company_logo_image?.image_url || ""}
              companyName={assessmentProposal?.agent?.company?.name}
              representativeName={assessmentProposal?.agent?.family_name + assessmentProposal?.agent?.first_name}
              proposalMessage={assessmentProposal?.proposal_content}
              proposalPrice={assessmentProposal?.proposal_price}
              propertyName={assessmentProposal?.property?.name}
              isUserApproved={assessmentProposal?.is_user_approved}
              isNew={!assessmentProposal?.is_user_read}
              isRead={assessmentProposal?.is_user_read}
              onClick={() => onClickAssessmentDetail(assessmentProposal.id)}
            />
          </Grid>
        ))}
      </Grid>
      <Box pt={2} pb={12}>
        <CustomPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Box>
    </Box>
  );
};
