import { makeStyles, Box, Typography } from "@material-ui/core";
import * as React from "react";

import OfferAchievement from "../icon/offer_achievement";
import OfferAdviceAndFollowup from "../icon/offer_advice_and_followup";
import OfferPartners from "../icon/offer_partners";
import OfferStrengthOfManagement from "../icon/offer_strength_of_management";
import OfferStrengthOfOthers from "../icon/offer_strength_of_others";
import OfferStrengthOfPartnerFinancialInstitutions from "../icon/offer_strength_of_partner_financial_institutions";
import OfferStrengthOfPropertiesForPurchase from "../icon/offer_strength_of_properties_for_purchase";
import OfferStrengthOfPropertiesForSale from "../icon/offer_strength_of_properties_for_sale";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
  },
}));

interface props {
  title:
    | "会社の実績"
    | "販売物件の強み"
    | "物件買取の強み"
    | "提携金融機関の強み"
    | "管理の強み"
    | "不動産以外の資産形成アドバイス、購入後アフターフォロー"
    | "税理士など提携パートナー"
    | "他アピールポイント";
  content: string;
}

export const OfferDetailContent: React.FC<props> = ({ title, content }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box mb={1 / 2} display="flex" flexDirection="row" alignItems="center">
        <Box mr={1 / 2}>
          {(() => {
            switch (title) {
              case "会社の実績":
                return <OfferAchievement />;
              case "販売物件の強み":
                return <OfferStrengthOfPropertiesForSale />;
              case "物件買取の強み":
                return <OfferStrengthOfPropertiesForPurchase />;
              case "提携金融機関の強み":
                return <OfferStrengthOfPartnerFinancialInstitutions />;
              case "管理の強み":
                return <OfferStrengthOfManagement />;
              case "不動産以外の資産形成アドバイス、購入後アフターフォロー":
                return <OfferAdviceAndFollowup />;
              case "税理士など提携パートナー":
                return <OfferPartners />;
              case "他アピールポイント":
                return <OfferStrengthOfOthers />;
              default:
                return null;
            }
          })()}
        </Box>
        {/* icon */}
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      </Box>
      <Typography variant="body1" color="textPrimary">
        {content}
      </Typography>
    </Box>
  );
};
