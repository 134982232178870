import { Chip, makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    margin: "4px 6px 4px 0px",
  },
}));

interface props {
  label: string;
}

export const OfferStrengthChip: React.FC<props> = ({ label }) => {
  const classes = useStyles();
  return <Chip label={label} size="small" classes={{ root: classes.chipRoot }} />;
};
