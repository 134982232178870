import { Box, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import ShakeHandsImage from "../../../../../assets/image/shakehands.png";
import { StyledModal } from "../../../../scripts/components/renewal_v1/styled_modal";

const useStyles = makeStyles((theme) => ({
  messageContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "24px 24px 0 24px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
  },
  button: {
    minWidth: 100,
  },
  description: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    textAlign: "center",
    "&:hover": {
      borderBottom: "none",
    },
  },
  image: {
    width: 150,
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      width: 200,
    },
  },
}));

interface props {
  offerType: "investment" | "assessment";
  onClose: () => void;
}
export const OfferApproveModal: React.FC<props> = ({ onClose, offerType }) => {
  const classes = useStyles();
  const handleClick = React.useCallback(() => {
    document.body.style.overflow = "auto"; // Enable scrolling
    onClose();
  }, [onClose]);

  // スクロール制御
  React.useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    return () => {
      document.body.style.overflow = "auto"; // Enable scroll when modal is unmounted
    };
  }, []);

  return (
    <StyledModal onClickOutside={handleClick}>
      <Box className={classes.messageContent}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h3">
            {offerType === "investment" ? "投資オファー" : "査定オファー"}の<br />
            マッチングが成立しました
          </Typography>
        </Box>
        {/* イラスト */}
        <img src={ShakeHandsImage} alt="ShakeHands" className={classes.image} />

        <Typography className={classes.description}>
          1営業日以内にエージェントからお電話いたします。お電話にて、面談の日程を調整してください。
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        <Typography
          variant="button"
          color="primary"
          className={`${classes.link} ${classes.button}`}
          onClick={handleClick}
        >
          閉じる
        </Typography>
      </Box>
    </StyledModal>
  );
};
