import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const OfferStrengthOfOthers: React.FC<props> = ({ width = "8", height = "8", fill = "#103894" }): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 14L10.56 10.58L14 9L10.56 7.44L9 4L7.43 7.44L4 9L7.43 10.58L9 14ZM2 0H16C17.1 0 18 0.89 18 2V16C18 16.53 17.79 17.04 17.41 17.41C17.04 17.79 16.53 18 16 18H2C1.47 18 0.96 17.79 0.59 17.41C0.21 17.04 0 16.53 0 16V2C0 0.89 0.89 0 2 0ZM2 16H16V2H2V16Z"
      fill={fill}
    />
  </svg>
);
export default OfferStrengthOfOthers;
