import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 342,
    margin: "auto",
    borderRadius: "4px 4px 0px 0px",
    borderStyle: "solid",
    borderWidth: "0px 0px 1px 0px",
    borderColor: theme.palette.divider,
  },
  media: {
    height: 200,
  },
}));

interface props {
  price: string;
  type: string;
  prefecture: string;
  address: string;
  year: string;
  structure: string;
  area: string;
  transactionPeriod: string;
}

export const PropertyCardNearbyPrice: React.FC<props> = ({
  price,
  type,
  prefecture,
  address,
  year,
  structure,
  area,
  transactionPeriod,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {/* <CardMedia className={classes.media} image={selectedImage} title="Property Image" /> */}
      <CardContent>
        <Box mb={2}>
          <Typography variant="caption" color="textSecondary">
            成約価格:
          </Typography>
          <Typography variant="h3" color="primary">
            {price}万円
          </Typography>
        </Box>
        <Box display="flex" pb={1}>
          <Typography variant="caption" color="textSecondary" component="p">
            種類: {type}
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="caption" color="textSecondary" component="p">
            都道府県: {prefecture}
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="caption" color="textSecondary" component="p">
            所在地: {address}
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="caption" color="textSecondary" component="p">
            建築年: {year} 構造: {structure} 面積: {area}㎡
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary" component="p">
            取引時期: {transactionPeriod}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
