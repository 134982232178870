import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import * as React from "react";
import { useHistory } from "react-router";

import MypageHeaderIcon from "../../../../assets/image/mypage_header_icon.png";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "16px 24px",
    borderTop: "1px solid #C3CBE9",
    borderBottom: "1px solid #C3CBE9",
    backgroundColor: theme.palette.background.paper,
  },
  textContainer: {
    flex: 1,
    marginLeft: theme.spacing(2),
  },
  icon: {
    fontSize: 40,
    color: theme.palette.primary.main,
  },
  button: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  illustration: {
    width: 50,
    height: 50,
  },
  arrowIcon: {
    color: theme.palette.primary.main, // 矢印の色を設定
    borderRadius: "50%", // 円形にするためにborder-radiusを50%に設定
    border: `2px solid ${theme.palette.primary.main}`, // 縁取りの色と太さを設定
    padding: theme.spacing(1), // パディングをテーマのスペーシングユニットに基づいて設定
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 32, // コンテナの幅
    height: 32, // コンテナの高さ
  },
}));

const Notification: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.container}>
      <img src={MypageHeaderIcon} alt="Illustration" className={classes.illustration} />
      <Box className={classes.textContainer}>
        <Typography variant="h4" color="primary">
          検討物件・保有物件がある方は
        </Typography>
        <Typography variant="h4" color="primary">
          物件の情報を登録してみましょう
        </Typography>
      </Box>
      <Box className={classes.arrowIcon} onClick={() => history.push("/add_new_property")}>
        <ArrowForwardIcon />
      </Box>
    </Box>
  );
};

export default Notification;
