import { Box, Card, CardContent, Chip, Typography, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";

import { IdName } from "../../../../api/generated";

import { AgentProfile } from "./agent_profile";
import { OfferStrengthChip } from "./strength_chip";

const useStyles = makeStyles((theme) => ({
  message: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 3,
  },
  read: {
    opacity: 0.7,
  },
}));

interface props {
  profileImagePath: string;
  proposalDate: string;
  companyImagePath: string;
  companyName: string;
  representativeName: string;
  companySpecialityTags: Array<IdName>;
  proposalMessage: string;
  isUserApproved?: boolean;
  isNew?: boolean;
  isRead?: boolean;
  onClick: () => void;
}

export const OfferInvestmentCard: React.FC<props> = ({
  profileImagePath,
  proposalDate,
  companyImagePath,
  companyName,
  representativeName,
  companySpecialityTags,
  proposalMessage,
  isUserApproved = false,
  isNew,
  isRead,
  onClick,
}) => {
  const classes = useStyles();
  const date = dayjs(proposalDate).format("YYYY年MM月DD日");

  const handleClick = () => {
    onClick();
  };

  // 特に強い分野の取得
  const strongFields = React.useMemo(() => {
    if (!companySpecialityTags) return [];
    const tags = companySpecialityTags.map((tag) => {
      return tag.name;
    });
    return tags;
  }, [companySpecialityTags]);

  return (
    <Card onClick={handleClick}>
      <CardContent className={isRead && classes.read}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">{date}</Typography>
          {isUserApproved && <Chip label="マッチング済" size="small" style={{ marginLeft: 8 }} />}
        </Box>
        <Box mt={1}>
          <AgentProfile
            profileImagePath={profileImagePath || ""}
            companyImagePath={companyImagePath || ""}
            companyName={companyName || ""}
            representativeName={representativeName || ""}
            isNew={isNew}
          />
        </Box>
        {/* 特に強い分野 */}
        {strongFields.length > 0 && (
          <Box mt={2}>
            <Typography variant="h5" color="primary">
              特に強い分野
            </Typography>
            <Box display="flex" flexWrap="wrap" mt={1}>
              {strongFields.map((field, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <OfferStrengthChip label={field} key={`strength-${index}`} />
              ))}
            </Box>
          </Box>
        )}

        {/* 紹介 */}
        <Box mt={2}>
          <Typography variant="body1" className={classes.message}>
            {proposalMessage}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
