import { Box, Card, CardContent, Chip, Typography, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";

import { AgentProfile } from "./agent_profile";
import { PropertyAppraisedPrice } from "./property_appraised_price";

const useStyles = makeStyles((theme) => ({
  message: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 3,
  },
  offerContent: {
    marginTop: 16,
    paddingTop: 16,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  read: {
    opacity: 0.7,
  },
}));

interface props {
  profileImagePath: string;
  proposalDate: string;
  companyImagePath: string;
  companyName: string;
  representativeName: string;
  proposalMessage: string;
  proposalPrice: number;
  propertyName: string;
  isUserApproved?: boolean;
  isNew?: boolean;
  isRead?: boolean;
  onClick: () => void;
}

export const OfferAssessmentCard: React.FC<props> = ({
  profileImagePath,
  proposalDate,
  companyImagePath,
  companyName,
  representativeName,
  proposalMessage,
  proposalPrice,
  propertyName,
  isUserApproved = false,
  isNew,
  isRead,
  onClick,
}) => {
  const classes = useStyles();
  const date = dayjs(proposalDate).format("YYYY年MM月DD日");

  const handleClick = () => {
    onClick();
  };

  return (
    <Card onClick={handleClick}>
      <CardContent className={isRead && classes.read}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">{date}</Typography>
          {isUserApproved && <Chip label="マッチング済" size="small" style={{ marginLeft: 8 }} />}
        </Box>
        <Box mt={1}>
          <AgentProfile
            profileImagePath={profileImagePath || ""}
            companyImagePath={companyImagePath || ""}
            companyName={companyName || ""}
            representativeName={representativeName || ""}
            isNew={isNew}
          />
        </Box>

        {/* 紹介 */}
        <Box mt={2}>
          <Typography variant="body1" className={classes.message}>
            {proposalMessage}
          </Typography>
        </Box>

        {/* 査定額 */}
        <Box className={classes.offerContent}>
          <PropertyAppraisedPrice proposalPrice={proposalPrice || 0} propertyName={propertyName || ""} />
        </Box>
      </CardContent>
    </Card>
  );
};
