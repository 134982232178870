import { Box, Grid, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router";

import { Property, ReinfolibPrice } from "../../../api/generated";
import { PropallyApiFactory } from "../../../module/custom_api_factory";
import { NavigationPath } from "../../../scripts/components/renewal_v1/enums";
import NavigationBar from "../../../scripts/components/renewal_v1/navigation";
import CustomPagination from "../../../scripts/components/renewal_v1/pagination";
import { PropertyCardNearbyPrice } from "../../../scripts/components/renewal_v1/property/card_nearby_price";
import { PropertyOperationTemplate } from "../../../scripts/components/renewal_v1/template/property_operation_template";
import { calculatePaginationNearbyPrice } from "../../../utilities/renewal_v1/pagination";
import { useDevice } from "../../../utilities/sp/use_device";
import { Loading } from "../../loading";

const useStyles = makeStyles((_theme) => ({
  background: {
    margin: "16px auto 0 auto",
    maxWidth: 716,
  },
  selectorWrapper: {
    textAlign: "right",
  },
  selector: {
    width: 200,
    textAlign: "left",
  },
}));

export const ManagementDetailNearbyPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [properties, setProperties] = React.useState<Property[]>([]);
  const [selectedPropertyId, setSelectedPropertyId] = React.useState<number>(undefined);
  const [nearbyPrices, setNearbyPrices] = React.useState<ReinfolibPrice[]>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  // ページネーション
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const { isMobile } = useDevice();

  const onClickPageBack = () => {
    history.goBack();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // MEMO: ロジックが１コンポーネントに集中してきているので追々はhooksに分割する
  React.useEffect(() => {
    const fetchProperties = async () => {
      const fp = PropallyApiFactory();
      try {
        const data = await fp.v1PropertiesGet({ withCredentials: true });
        if (data.status === 200) {
          setProperties(data.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchProperties();
  }, []);

  React.useEffect(() => {
    if (selectedPropertyId !== undefined) {
      const fetchNearbyPrices = async () => {
        setLoading(true);

        const fp = PropallyApiFactory();
        try {
          const data = await fp.v1PropertiesPropertyIdNearbyPricesGet(selectedPropertyId, { withCredentials: true });
          if (data.status === 200) {
            setNearbyPrices(data.data);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      };
      fetchNearbyPrices();
    }
  }, [selectedPropertyId, properties]);

  // ページネーションのためのロジック
  const { currentNearbyPrices, totalPages } = calculatePaginationNearbyPrice(nearbyPrices, currentPage);

  if (loading) {
    return (
      <PropertyOperationTemplate title="周辺の成約事例" onBack={onClickPageBack}>
        <Loading />
      </PropertyOperationTemplate>
    );
  }

  return (
    <PropertyOperationTemplate title="周辺の成約事例" onBack={onClickPageBack}>
      <Box className={classes.selectorWrapper} mt={4} mx={4}>
        {properties.length === 0 ? (
          <span>登録物件を選択して下さい</span>
        ) : (
          <Select
            name="property"
            value={selectedPropertyId || 0}
            className={classes.selector}
            onChange={(e) => setSelectedPropertyId(e.target.value as number)}
          >
            {properties.map((property) => (
              <MenuItem key={property.id} value={property.id}>
                {property.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>

      {/* 事例 */}
      <Box className={classes.background}>
        {!isMobile && (
          <Box pt={1} pb={3} display="flex">
            <Typography variant="h2">周辺の成約事例</Typography>
          </Box>
        )}
        <Grid container spacing={2}>
          {nearbyPrices === undefined ? (
            <Box mx={4}>No Nearby Prices</Box>
          ) : nearbyPrices.length === 0 ? (
            <Box mx={4}>
              同エリアで情報が見つかりませんでした。
              <br />
              対象物件の市区町村が未登録の場合は登録してください。
            </Box>
          ) : (
            currentNearbyPrices.map((price, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} sm={6} key={index}>
                <PropertyCardNearbyPrice
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  price={`${Math.round(Number(price.TradePrice) / 10000).toLocaleString()}`}
                  type={price.Type}
                  prefecture={price.Prefecture}
                  address={price.Municipality + price.DistrictName}
                  year={price.BuildingYear}
                  structure={price.Structure}
                  area={price.Area}
                  transactionPeriod={price.Period}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Box pt={2} pb={12}>
        <CustomPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Box>

      {/* TODO: PropertyOperationTemplateにPCフッターが内包されているため局所的な対応をしてしまっているが、汎用的な定義で再現されるように直す */}
      {isMobile && <NavigationBar path={NavigationPath.SUCCESSFUL_CASES} />}
    </PropertyOperationTemplate>
  );
};
