import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const OfferStrengthOfManagement: React.FC<props> = ({
  width = "18",
  height = "22",
  fill = "#103894",
}): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 10C18 15.55 14.16 20.74 9 22C3.84 20.74 0 15.55 0 10V4L9 0L18 4V10ZM9 20C12.75 19 16 14.54 16 10.22V5.3L9 2.18L2 5.3V10.22C2 14.54 5.25 19 9 20ZM8 13H10V16H13V11H15L9 6L3 11H5V16H8V13Z"
      fill={fill}
    />
  </svg>
);
export default OfferStrengthOfManagement;
