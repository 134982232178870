import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: "#103894", // Primary Blue 900
      light: "#E7EAF6", // Primary Light
      dark: "#00277F", // Primary Dark
    },
    secondary: {
      main: "#EC6C17", // Secondary Mint Green 400
      light: "#FFF3E1", // Secondary Light
      dark: "#E25213", // Secondary Dark
    },
    error: {
      main: "#DE351F", // Attention Red 400
      light: "#FBE9E8", // Attention Red Light
    },
    warning: {
      main: "#FEA532", // Warning Orange 400
      light: "#FFF3E1", // Warning Light (Orange/50)
    },
    info: {
      main: "#0085FF", // Link Blue
    },
    success: {
      main: "#009578", // Success Green 400
      light: "#E1F4F0", // Success Light
    },
    grey: {
      900: "#212121",
      700: "#616161",
      400: "#BDBDBD",
      300: "#E0E0E0",
      50: "#FAFAFA",
    },
    background: {
      default: "#F7F8FB", // Background on white
      paper: "#FFF",
    },
    text: {
      primary: "#212121",
      secondary: "#616161",
      disabled: "#BDBDBD",
    },
    // 独自定義のカラー
    transparent: "rgba(33, 33, 33, 0.6)",
    tertiary: {
      main: "#424242",
      light: "#F5F5F5",
      dark: "#212121",
      contrastText: "#FFF",
    },
    chart: {
      income: {
        primary: "#103894",
        darker: "#1B42A0",
        dark: "#2D54B6",
      },
      expense: {
        primary: "#E25213",
        darker: "#EC6C17",
        dark: "#F98B1A",
        light: "#FEA532",
        lighter: "#FFBD45",
        lightest: "#FFD662",
      },
    },
  },
  typography: {
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
    h1: {
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "130%",
      "@media (max-width:600px)": {
        fontSize: "24px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    h2: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "130%",
      "@media (max-width:600px)": {
        fontSize: "20px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    h3: {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "130%",
      "@media (max-width:600px)": {
        fontSize: "18px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    h4: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "130%",
      "@media (max-width:600px)": {
        fontSize: "14px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    h5: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "130%",
      "@media (max-width:600px)": {
        fontSize: "12px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    h6: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "130%",
      "@media (max-width:600px)": {
        fontSize: "10px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "150%",
      "@media (max-width:600px)": {
        fontSize: "14px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "150%",
      "@media (max-width:600px)": {
        fontSize: "12px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    button: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "130%",
      "@media (max-width:600px)": {
        fontSize: "14px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "150%",
      "@media (max-width:600px)": {
        fontSize: "10px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768, // タブレットでの見た目をPCと同じにするため、mdのみ値をデフォルトから変更
      lg: 1280,
      xl: 1920,
    },
  },
  // MUI components override styles
  overrides: {
    MuiBottomNavigation: {
      root: {
        position: "fixed",
        width: "100%",
        bottom: 0,
        height: "74px", // default 56px
        paddingBottom: "18px",
        borderTop: "1px solid #E0E0E0",
        zIndex: 1399,
      },
    },
    MuiBottomNavigationAction: {
      label: {
        fontSize: "12px",
        "&$selected": {
          fontSize: "12px",
        },
      },
    },
  },
});

export default theme;

// Create a theme instance.

// Material-UIのパレットに新しい色を追加するために、型を拡張します
declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    transparent: string;
    tertiary: Palette["primary"];
    chart: {
      income: {
        primary: string;
        darker: string;
        dark: string;
      };
      expense: {
        primary: string;
        darker: string;
        dark: string;
        light: string;
        lighter: string;
        lightest: string;
      };
    };
  }
  interface PaletteOptions {
    transparent?: string;
    tertiary?: Palette["primary"];
    chart?: {
      income?: {
        primary?: string;
        darker?: string;
        dark?: string;
      };
      expense?: {
        primary?: string;
        darker?: string;
        dark?: string;
        light?: string;
        lighter?: string;
        lightest?: string;
      };
    };
  }
}
